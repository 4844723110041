<template>
  <div class="about">
    <!-- Slider main container -->
    <div class="swiper">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide">Slide 1</div>
        <div class="swiper-slide">Slide 2</div>
        <div class="swiper-slide">Slide 3</div>
      </div>
      <!-- If we need pagination -->
      <div class="swiper-pagination"></div>

      <!-- If we need navigation buttons -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>

      <!-- If we need scrollbar -->
      <div class="swiper-scrollbar"></div>
    </div>
  </div>
</template>
<script setup>
  import { onMounted } from 'vue'
  onMounted(()=>{
    initSwiper()
  })
  function initSwiper(){
    const swiper = new Swiper('.swiper', {
      loop: true,
      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
      },
       autoplay: {
       delay: 5000,
     },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      // And if we need scrollbar
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    })
  }
</script>
<style lang="scss">
  .about{
    position: relative;
    padding-top: 200px;
  }
</style>